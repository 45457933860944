import React, { Component, setState } from "react";
import { Link } from "react-router-dom";

class Navbar extends Component {
  state = {
    navlink: "",
  };
  handleSubmit = (event) => {
    event.preventDefault();
    console.log(event.target.searchName.value);
  };
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            CyclingResults
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/">
                  Home
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#">
                  Rankings
                </a>
              </li> */}
            </ul>
            <form className="d-flex" method="get" action={this.state.navlink}>
              <input
                className="form-control me-2"
                type="text"
                placeholder="Search Rider"
                aria-label="Search"
                onChange={(e) =>
                  this.setState({ navlink: `/search/${e.target.value}` })
                }
              ></input>

              <button className="btn btn-outline-primary" type="submit">
                Search
              </button>
            </form>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
