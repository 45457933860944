import React, { Component } from "react";

class RecentRaces extends Component {
  state = {
    races: [],
  };

  getRecentRaces = async () => {
    try {
      const today = new Date();
      const end =
        String(today.getDate()).padStart(2, "0") +
        "-" +
        String(today.getMonth() + 1).padStart(2, "0") +
        "-" +
        today.getFullYear();

      const n_ago = new Date(today.getDate());
      const start =
        String(n_ago.getDate()).padStart(2, "0") +
        "-" +
        String(n_ago.getMonth() + 1).padStart(2, "0") +
        "-" +
        n_ago.getFullYear();

      let fetchRes = await fetch(
        `https://laravel-api.usacycling.org/api/v1/event_search?name=snake%2520alley&start_date=2023-07-31`
      );
      // fetchRes is the promise to resolve
      // it by using.then() method
      await fetchRes
        .then((res) => res.json())
        .then((d) => {
          console.log(d);
          this.setState({ races: d });
        });
      // const response = fetch(

      // );
      // console.log(response);
      // const data = response.json();
      // console.log(data);
      // //setRiderInfo(data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  render() {
    this.getRecentRaces();
    return <h1>Recent Races</h1>;
  }
}

export default RecentRaces;
