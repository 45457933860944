import React, { useState, useEffect } from "react";
import ResultEntry from "./components/result_entry.jsx";
import { useParams } from "react-router-dom";
import RiderInfoBox from "./components/rider_info_box.jsx";

const Rider = () => {
  const { usacnum } = useParams();
  const [riderInfo, setRiderInfo] = useState([]);
  const [results, setResults] = useState([]);
  const [total_races, setTotalRaces] = useState(-1);
  const [wins, setWins] = useState(-1);
  const [wins_current_year, setWinsCurrentYear] = useState(-1);
  const [races_current_year, setRacesCurrentYear] = useState(-1);
  const [win_qualities, setWinQualities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.usacycling.org/user_results?comp_id=${usacnum}&from_date=2018-01-01`
        );
        //await console.log(response.status);

        const data = await response.json();
        //const fieldNames = Object.keys(data[0]);

        setResults(data.reverse());
      } catch (error) {
        console.log("Error fetching results:", error);
      }
    };

    const fetchOldest = async () => {
      let year = 1990;
      while (year < 2018) {
        try {
          const response = await fetch(
            `https://api.usacycling.org/user_results?comp_id=${usacnum}&from_date=${year}-01-01`
          );
          const data = await response.json();
          setResults(data.reverse());
          setIsLoading(false);
          break;
        } catch (error) {
          console.log("Error fetching results:", error);
        }
        year++;
      }
    };

    fetchData();
    fetchOldest();
  }, [usacnum]); // Add 'usacnum' as a dependency

  useEffect(() => {
    const calculateStats = () => {
      const cur_year = new Date().getFullYear();
      let wins = 0;
      let cur_year_races = 0;
      let cur_year_wins = 0;
      let win_qualities = [];
      for (let i = 0; i < results.length; i++) {
        if (results[i].place === "1") {
          wins++;
          if (results[i].points != null) {
            win_qualities.push(parseFloat(results[i].points));
          }
          //
          if (cur_year.toString() === results[i].event_year) {
            cur_year_wins++;
          }
        }
        if (cur_year.toString() === results[i].event_year) {
          cur_year_races++;
        }
      }
      setWins(wins);
      setTotalRaces(results.length);
      setRacesCurrentYear(cur_year_races);
      setWinsCurrentYear(cur_year_wins);
      setWinQualities(win_qualities);
    };

    if (results.length > 0) {
      calculateStats();
    }
  }, [results]);

  useEffect(() => {
    const fetchRider = async () => {
      try {
        const response = await fetch(
          `https://api.cyclingresults.us/get_rider?comp_id=${usacnum}`
        );
        const data = await response.json();
        setRiderInfo(data);
      } catch (error) {
        console.log("Error fetching rider:", error);
      }
    };

    // Call fetchRider after defining it
    fetchRider();
  }, [usacnum]);

  return (
    <React.Fragment>
      <div className="max-w-container-2">
        <div className="row">
          <div className="col-sm p-1">
            {riderInfo.length > 0 ? (
              <RiderInfoBox
                name={riderInfo[0].Name}
                race_age={riderInfo[0].Race_Age}
                location={riderInfo[0].Location}
                compID={usacnum}
              ></RiderInfoBox>
            ) : (
              <React.Fragment>
                <p className="m-0">Unable to load rider info</p>
                <p className="m-0">USAC Number: {usacnum}</p>
              </React.Fragment>
            )}
          </div>
          <div className="col-sm p-1">
            {/* Stats box in this level beacuse of all the issues with passing with condaitionals. Using state vars instead so it auto updates.
          TODO: maybe add drop down for year select. Add graphs and shit.
          */}
            <div className="border border-2 rounded-3">
              <h2 className="ms-1">Stats</h2>
              {results.length > 0 ? (
                <React.Fragment>
                  <p className="m-0 ms-2">
                    Total Wins: {wins} (
                    {((wins / total_races) * 100).toFixed(2)}%)
                  </p>
                  <p className="m-0 ms-2">
                    {new Date().getFullYear()} Wins: {wins_current_year} (
                    {((wins_current_year / races_current_year) * 100).toFixed(
                      2
                    )}
                    %)
                  </p>
                  <p className="m-0 ms-2">
                    Win Quality:{" "}
                    {win_qualities.length === 0
                      ? "NaN"
                      : (
                          win_qualities.reduce((a, b) => a + b) /
                          win_qualities.length
                        ).toFixed(2)}
                  </p>{" "}
                </React.Fragment>
              ) : (
                <p className="m-0 ms-2">None</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-container-2 border border-2 rounded-3 p-3">
        <div className="row fw-bold">
          <div className="col-sm ">Date</div>
          <div className="col-sm">Discipline</div>

          <div className="col-sm-3">Race</div>
          <div className="col-sm-2">Category</div>
          <div className="col-sm-2">Team</div>
          <div className="col-sm-1 ">Place</div>
          <div className="col-sm-1">Points</div>
        </div>
        <div className=" scroll-view">
          {results.length > 0 ? (
            results.map((item, index) => (
              <ResultEntry
                key={index}
                data_key={index}
                results_id={item.results_id}
                results_race_id={item.results_race_id}
                compid={item.compid}
                bib={item.bib}
                team={item.team}
                laps={item.laps}
                split={item.split}
                place={item.place}
                points={item.points}
                ucicode={item.ucicode}
                gcleader={item.gcleader}
                event_name={item.event_name}
                cyc_org={item.cyc_org}
                event_year={item.event_year}
                event_id={item.event_id}
                permit={item.permit}
                race_date={item.race_date}
                race_discipline={item.race_discipline}
                category={item.category}
                race_age={item.race_age}
                race_class={item.race_class}
                rank_discipline={item.rank_discipline}
                rank_discipline_name={item.rank_discipline_name}
              />
            ))
          ) : (
            <p>No results found</p>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Rider;
