import React, { Component } from "react";
import { Link, Route } from "react-router-dom";

class ResultEntry extends Component {
  state = {
    data_key: this.props.data_key,

    results_id: this.props.results_id,
    results_race_id: this.props.results_race_id,
    compid: this.props.compid,
    bib: this.props.bib,
    team: this.props.team,
    laps: this.props.laps,
    split: this.props.split,
    place: this.props.place,
    points: this.props.points,
    ucicode: this.props.ucicode,
    gcleader: this.props.gcleader,
    event_name: this.props.event_name,
    cyc_org: this.props.cyc_org,
    event_year: this.props.event_year,
    event_id: this.props.event_id,
    permit: this.props.permit,
    race_date: this.props.race_date,
    race_discipline: this.props.race_discipline,
    category: this.props.category,
    race_age: this.props.race_age,
    race_class: this.props.race_class,
    rank_discipline: this.props.rank_discipline,
    rank_discipline_name: this.props.rank_discipline_name,

    pal: {
      // Road Events
      CRIT: "#F94144",
      CRITERIUM: "#F94144",
      RR: "#F94144",
      TT: "#F94144",
      "TIME TRIALL": "#F94144",
      "INDIVIDUAL TIME TRIA": "#F94144",
      "TIME TRIAL": "#F94144",
      "INDIVIDUAL TIME TRIAL": "#F94144",
      ITT: "#F94144",
      TTT: "#F94144",
      CCR: "#F94144",
      ROAD: "#F94144",
      "TIME TRIAL": "#F94144",
      "ROAD RACE": "#F94144",
      "HILL SPRINT": "#F94144",
      HC: "#F94144",
      CR: "#F94144",
      "CIRCUIT RACE": "#F94144",

      // Multi-day
      OMNI: "#9289fa",
      OMNIUM: "#9289fa",
      STR: "#9289fa",
      "STAGE RACE": "#9289fa",
      GC: "#9289fa",
      "GENERAL CLASSIFICATION": "#9289fa",
      "GENERAL CLASSIFICATI": "#9289fa",

      //Zwift and stuff
      XCU: "#FC6719",

      // Mountain
      XC: "#8B4513",
      AM: "#8B4513",
      STXC: "#8B4513",
      DH: "#8B4513",
      DS: "#8B4513",
      SD: "#8B4513",
      "SUPER D": "#8B4513",
      "MTN BIKE": "#8B4513",
      FB: "#8B4513",
      TR: "#8B4513",
      Marathon: "#8B4513",
      XCM: "#8B4513",
      CM: "#8B4513",
      "CROSS COUNTRY": "#8B4513",
      "SHORT TRACK": "#8B4513",
      MX: "#8B4513",
      ED: "#8B4513",
      "DUAL SLALOM": "#8B4513",

      ENDURO: "#8B4513",
      MARATHON: "#8B4513",
      STX: "#8B4513",
      ED: "#8B4513",

      // Dirt (not mountain)
      GRAVEL: "#006400",
      // CX
      CX: "#43AA8B",
      "CYCLO-CROSS": "#43AA8B",
      "CYCLO CROSS": "#43AA8B",
      CYCLOCROSS: "#43AA8B",
      "CYCLOCROSS MEN'S OPEN": "#43AA8B",

      // Track
      TRACK: "#277DA1",
      SR: "#277DA1",
      MO: "#277DA1",
      KEIR: "#277DA1",
      PR: "#277DA1",
      ELIMINATION: "#277DA1",
      "WIN AND OUT": "#277DA1",
      IP: "#277DA1",
      TP: "#277DA1",
      IO: "#277DA1",
      MAD: "#277DA1",
      TRK: "#277DA1",
      ELIM: "#277DA1",
      ELIMI: "#277DA1",
      TSPR: "#277DA1",
      "OMNIUM SCRATCH RACE": "#277DA1",
      SPR: "#277DA1",
      TEMP: "#277DA1",
      "MISS AND OUT": "#277DA1",
      FTT: "#277DA1",
      SCRATCH: "#277DA1",
      "TRACK-500M TT": "#277DA1",
      "TRACK-200M TT": "#277DA1",
      "TRACK-4000M TT": "#277DA1",
      "TRACK-2000M TT": "#277DA1",
      "500 M TT": "#277DA1",
      "TEMPO POINTS RACE": "#277DA1",
      SNOWBALL: "#277DA1",
      TEMPO: "#277DA1",

      // Other (non-competitive)
      "BEGINNING RACER PROGRAM": "#000000",
      "UNKNOWN DISTANCE": "#000000",
      "GRAN FONDO": "#000000",
    },
  };

  // "results_id":"17328980",
  // "results_race_id":"1117294",
  // "compid":"538985",
  // "bib":"704",
  // "team":"",
  // "time":"",
  // "laps":"0",
  // "split":"",
  // "place":"3",
  // "points":"573.34",
  // "ucicode":"",
  // "gcleader":"",
  // "event_name":"State Games of America Time Trial, TTT, Great Lakes Classic RR",
  // "cyc_org":"USCF",
  // "event_year":"2017",
  // "event_id":"1586",
  // "permit":"2017-1586~A",
  // "race_date":"2017-08-06",
  // "race_discipline":"RR",
  // "category":"",
  // "race_age":"9-14",
  // "race_class":"Juniors",
  // "rank_discipline":"RR",
  // "rank_discipline_name":"Road Race"

  getColor = () => {
    // spaces added so concatinting doesn't break the rendering
    return this.state.data_key % 2 ? " row-bg-odd" : " row-bg-even";
    //return ' row-bg-odd';
  };

  getDisColor = () => {
    const key = this.state.race_discipline.toUpperCase();
    if (key in this.state.pal) {
      return this.state.pal[this.state.race_discipline.toUpperCase()];
    } else {
      console.log(key + " not in dict");
      return "#000000";
    }
  };

  render() {
    return (
      <div className={"container-flex"}>
        <div className={"row" + this.getColor()}>
          <div className="col-sm">
            {new Date(this.state.race_date).toDateString()}
          </div>

          <div className="col-sm">
            <svg calssname="align-bottom" width="20" height="20">
              <circle cx={10} cy={7.5} r={7.5} fill={this.getDisColor()} />
            </svg>{" "}
            {/* {!this.state.rank_discipline_name ? this.state.race_discipline : this.state.rank_discipline_name}  */}
            {this.state.race_discipline}
          </div>

          <div className="col-sm-3">
            <Link
              to={`/race/${this.state.event_year}/${this.state.event_id}/${this.state.results_race_id}`}
            >
              {this.state.event_name}
            </Link>
          </div>
          <div className="col-sm-2">
            {this.state.category} {this.state.race_class}
          </div>
          <div className="col-sm-2">{this.state.team}</div>
          <div className="col-sm-1 ">{this.state.place}</div>
          <div className="col-sm-1">{this.state.points}</div>
        </div>
      </div>
    );
  }
}

export default ResultEntry;
