import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import Rider from "./rider";
import ResultEntry from "./components/result_entry.jsx";
import "./custom_css/custom.css";
import Race from "./race.js";
import Search from "./search.js";
import Navbar from "./components/navbar";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Link,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Navbar></Navbar>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/search" element={<Search />} />
        <Route path="/rider/:usacnum" element={<Rider />} />
        <Route
          path="race/:event_year/:event_id/:results_race_id"
          element={<Race />}
        />
        <Route path="race/:event_year/:event_id" element={<Race />} />
        <Route path="search/:searchName" element={<Search />} />
        <Route path="*" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
reportWebVitals();
