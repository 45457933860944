import React, { useState, useEffect } from "react";
import ResultEntry from "./components/result_entry.jsx";
import { useParams, Link, useNavigate, redirect } from "react-router-dom";
import RiderInfoBox from "./components/rider_info_box.jsx";

const Search = () => {
  const [riderSearch, setRiderSearch] = useState([]);
  const [haveSearched, setHaveSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { searchName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchName != undefined) {
      try {
        setIsLoading(true);
        let fetchRes = fetch(
          `https://api.cyclingresults.us/search?name=${searchName}`
        );
        // fetchRes is the promise to resolve
        // it by using.then() method
        fetchRes
          .then((res) => res.json())
          .then((d) => {
            console.log(d);
            setRiderSearch(d);
            setHaveSearched(true);
            setIsLoading(false);
          });
        // const response = fetch(

        // );
        // console.log(response);
        // const data = response.json();
        // console.log(data);
        // //setRiderInfo(data);
      } catch (error) {
        console.log("Error fetching rider:", error);
      }
    }
  }, [searchName]);

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`/search/${event.target.searchName.value}`);

    // try {
    //   let fetchRes = fetch(
    //     `http://127.0.0.1:5000/search?name=${event.target.searchName.value}`
    //   );
    //   // fetchRes is the promise to resolve
    //   // it by using.then() method
    //   fetchRes
    //     .then((res) => res.json())
    //     .then((d) => {
    //       console.log(d);
    //       setRiderSearch(d);
    //     });
    //   // const response = fetch(

    //   // );
    //   // console.log(response);
    //   // const data = response.json();
    //   // console.log(data);
    //   // //setRiderInfo(data);
    // } catch (error) {
    //   console.log("Error fetching rider:", error);
    // }
  };

  return (
    <React.Fragment>
      <div className="max-w-container">
        <form onSubmit={handleSubmit} class="d-flex">
          <input
            class="form-control me-2"
            type="search"
            placeholder="Search Rider"
            aria-label="Search"
            name="searchName"
            defaultValue={searchName}
          ></input>
          <button class="btn btn-outline-primary" type="submit">
            Search
          </button>
        </form>
        <div>
          {/* This is actually insane. It should not look like this. */}
          {isLoading
            ? "Loading..."
            : !haveSearched
            ? ""
            : riderSearch.length === 0
            ? "No Riders Found"
            : riderSearch.map((item, index) => (
                <React.Fragment>
                  <Link key={index} to={`/rider/${item.CompID}`}>
                    {item.Name} {item.Location}
                  </Link>
                  <br></br>
                </React.Fragment>
              ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Search;
