import React, { useState, useEffect } from "react";
import ResultEntry from "./components/result_entry.jsx";
import { useParams, useSearchParams } from "react-router-dom";
import RiderInfoBox from "./components/rider_info_box.jsx";
import RaceEntry from "./components/race_entry.jsx";

const Race = () => {
  const { event_year, event_id, results_race_id } = useParams();

  const [raceCats, setRaceCats] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let data = [];
      let new_data;
      let page_start = 0;

      // try {
      //   const response = await fetch(
      //     `https://api.usacycling.org/races?event_id=${event_id}&page_limit=50&event_year=${event_year}&race_id=${results_race_id}`
      //   );
      //   new_data = await response.json();
      //   for (let i = 0; i < new_data.length; i++) {
      //     data.push(new_data[i]);
      //   }
      //   setRaceCats(data);
      // } catch (error) {
      //   console.log("Error fetching results:", error);
      // }

      do {
        try {
          const response = await fetch(
            `https://api.usacycling.org/races?event_id=${event_id}&page_limit=50&event_year=${event_year}&page_start=${page_start}`
          );
          new_data = await response.json();
          for (let i = 0; i < new_data.length; i++) {
            data.push(new_data[i]);
          }

          //console.log(new_data);

          page_start += 50;
        } catch (error) {
          console.log("Error fetching results:", error);
        }
      } while (new_data.length > 0);
      setRaceCats(data);
      const element = document.getElementById("race-entry-" + results_race_id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      {raceCats.length > 0 ? (
        <span>
          <h1 className="text-center">{raceCats[0].event_name}</h1>
          <h3 className="text-center">
            {raceCats[0].race_city + ", " + raceCats[0].race_state}
          </h3>

          {/* <h3>{raceCats[0].race_type || raceCats[0].discipline}</h3> */}
        </span>
      ) : (
        <h1></h1>
      )}

      <div className="max-w-container-2 ">
        {raceCats.map((item, index) => (
          <div
            id={"race-entry-" + item.race_id}
            className="mt-1 mb-1 ms-0 me-0"
          >
            <RaceEntry
              key={index}
              expanded={
                item.race_id == results_race_id || results_race_id === "all"
              }
              race_id={item.race_id}
              category={item.category}
              gender={item.gender}
              age={item.age}
              class={item.class}
              rtype={item.rtype}
              distance={item.distance}
              distance_type={item.distance_type}
              split_type={item.split_type}
              rank_race={item.rank_race}
              event_name={item.event_name}
              event_year={item.event_year}
              event_id={item.event_id}
              race_date={item.race_date}
              race_city={item.race_city}
              race_state={item.race_state}
              race_zip={item.race_zip}
              cyc_org={item.cyc_org}
              race_type={item.race_type}
              discipline={item.discipline}
              competitive={item.competitive}
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Race;
