import React, { Component } from 'react';
import {  Link, Route } from "react-router-dom";



class RacePageResult extends Component {
    
    state = { 
        data_key: this.props.data_key,
        

        event_name: this.props.event_name,
        race_date: this.props.race_date,
        race_city: this.props.race_city,
        race_state: this.props.race_state,
        cyc_org: this.props.cyc_org,
        race_type: this.props.race_type,
        discipline: this.props.discipline,
        competitive: this.props.competitive,
        gender: this.props.gender,
        age: this.props.age,
        class: this.props.class,
        category: this.props.category,
        distance: this.props.distance,
        distance_type: this.props.distance_type,
        place: this.props.place,
        time: this.props.time,
        laps: this.props.laps,
        split: this.props.split,
        points: this.props.points,
        first: this.props.first,
        last: this.props.last,
        compid: this.props.compid,
        bib: this.props.bib,
        team: this.props.team,
        comp_city: this.props.comp_city,
        comp_state: this.props.comp_state,
        comp_hometown: this.props.comp_hometown,

        // {
        //     "event_name": "The Tour of Saint Louis Omnium",
        //     "race_date": "2023-03-19",
        //     "race_city": "Chesterfield",
        //     "race_state": "MO",
        //     "cyc_org": "USCF",
        //     "race_type": "Road",
        //     "discipline": "CRIT",
        //     "competitive": "Y",
        
        //     "gender": "M",
        //     "age": "",
        //     "class": "Collegiate",
        //     "category": "Pro/1/2/3",
        //     "distance": "",
        //     "distance_type": "",
        //     "place": "1",
        
        //     "time": "",
        //     "laps": "0",
        //     "split": "",
        //     "points": "123.96",
        //     "first": "Asa",
        //     "last": "Black",
        //     "compid": "438977",
        //     "bib": "97",
        //     "team": "Move Up Cycling Club",
        //     "comp_city": "LEAWOOD",
        //     "comp_state": "KS",
        //     "comp_hometown": ""
        // }
    };

   


    getColor = () =>{
        // spaces added so concatinting doesn't break the rendering
        return this.state.data_key % 2 ?  ' row-bg-odd' : ' row-bg-even';
        //return ' row-bg-odd';
    }

    

    

    render() { 
        return (
            <div className={'container-flex' }>
      
                
                <div className={"row ms-1 me-1" + this.getColor()}>
                    <div className="col-1">
                    
                        {this.state.place}
                    </div>


                    <div className='col-4'>
                    {
                        (this.state.compid == 0) ? (<span>{this.state.first + ' ' + this.state.last}</span>) : (<Link to={`/rider/${this.state.compid}`}>{this.state.first + ' ' + this.state.last}</Link>) 
                    }
                    </div>
                    
                    <div className="col-5">
                        {this.state.team}
                       
                    </div>
                    
                    <div className='col-1'>
                        {this.state.points}
                    </div> 
                    
                </div>
           </div>
            
        
        );
    }
}
 
export default RacePageResult;