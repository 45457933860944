import React, { Component } from "react";

class RiderInfoBox extends Component {
  state = {
    name: this.props.name,
    compID: this.props.compID,
    location: this.props.location,
    race_age: this.props.race_age,
    usac_points: this.props.usac_points,
    usac_rank: this.props.usac_rank,
  };
  render() {
    return (
      <div className="border border-2 rounded-3">
        <h2 className="ms-1">{this.state.name}</h2>
        <p className="m-0 ms-2">{this.state.location}</p>
        <p className="m-0 ms-2">
          Race Age:{" "}
          {this.state.race_age === 250 ? "Old Slug" : this.state.race_age}
        </p>
        <p className="m-0 ms-2">USAC Number: {this.state.compID}</p>
      </div>
      //[{"CompID":513137,"Location":"Chelsea, MI","Name":"Connell Alford","Race_Age":17,"USAC_Points":590.08,"USAC_Rank":10291}]    <p className='m-0'>12 Month Wins: : {this.state.year_win_percent}</p>
    );
  }
}

export default RiderInfoBox;
