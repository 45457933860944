import logo from "./logo.svg";
import "./App.css";

import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ResultEntry from "./components/result_entry.jsx";
import { useParam } from "react-router-dom";
import Search from "./search.js";
import RecentRaces from "./components/recent_races";
import Navbar from "./components/navbar";

function App() {
  return (
    <React.Fragment>
      <div className="max-w-container">
        <h2>Rider Search</h2>
        <Search></Search>
      </div>
    </React.Fragment>
  );
}

export default App;
