import React, { Component, Image } from 'react';
import {  Link, Route } from "react-router-dom";
import RacePageResult from './race_page_result';

class RaceEntry extends Component {
    
    state = { 
        expanded: this.props.expanded,

        race_id: this.props.race_id,
        category: this.props.category,
        gender: this.props.gender,
        age: this.props.age,
        class: this.props.class,
        rtype: this.props.rtype,
        distance: this.props.distance,
        distance_type: this.props.distance_type,
        split_type: this.props.split_type,
        rank_race: this.props.rank_race,
        event_name: this.props.event_name,
        event_year: this.props.event_year,
        event_id: this.props.event_id,
        race_date: this.props.race_date,
        race_city: this.props.race_city,
        race_state: this.props.race_state,
        race_zip: this.props.race_zip,
        cyc_org: this.props.cyc_org,
        race_type: this.props.race_type,
        discipline: this.props.discipline,
        competitive: this.props.competitive,

        results_list: [],
     } 

    //  {"race_id":"1468561",
//     "category":"Open",
//     "gender":"F",
//     "age":"",
//     "class":"",
//     "rtype":"",
//     "distance":"",
//     "distance_type":"",
//     "split_type":null,
//     "rank_race":"Y",
//     "event_name":"2023 USA Cycling Collegiate Road National Championship",
//     "event_year":"2023",
//     "event_id":"9685",
//     "race_date":"2023-05-05",
//     "race_city":"Albuquerque",
//     "race_state":"NM","race_zip":"87102",
//     "cyc_org":"USCF",
//     "race_type":"Road",
//     "discipline":"ITT",
//     "competitive":"Y"
// },
    render() { 
        let content;

        if (this.state.expanded) {
            if(this.state.results_list.length == 0){
                try {
                    let fetchRes = fetch(
                        `https://api.usacycling.org/race_results?results_race_id=${this.state.race_id}`);
                        // fetchRes is the promise to resolve
                        // it by using.then() method
                        fetchRes.then(res =>
                            res.json()).then(d => {
                                //console.log(d);
                                this.setState({ results_list: d });
                                //setRiderSearch(d);
                        })
                    // const response = fetch(
                      
                    // );
                    // console.log(response);
                    // const data = response.json();
                    // console.log(data);
                    // //setRiderInfo(data);
                  } catch (error) {
                    console.log('Error fetching rider:', error);
                  }
            }
            


            content = 
            <React.Fragment>
                <div className='container-flex'>
                    <div className='row'  onClick={() => this.setState({ expanded: false })}>
                        <div className='col-sm-auto me-0 ms-0'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="m283-345-43-43 240-240 240 239-43 43-197-197-197 198Z"/></svg>
                        </div>
                        <div className='col-sm '>
                            <h5>{new Date(this.state.race_date).toDateString()} {this.state.gender} {this.state.discipline} {this.state.category} {this.state.age} {this.state.class}</h5>
                        </div>
                    </div>
                    <div className='row ms-1 me-1'>
                        <div className='col-sm-auto'>
                            Gender: {this.state.gender}
                        </div>
                        <div className='col-sm-auto'>
                            Category: {this.state.category}
                        </div>
                        <div className='col-sm-auto'>
                            Discipline: {this.state.discipline}
                        </div>
                        <div className='col-sm-auto'>
                            Class: {this.state.class}
                        </div>
                        <div className='col-sm-auto'>
                            Race Type: {this.state.race_type}
                        </div>
                    </div>
                    <div className={"row ms-1 me-1 fw-bold"}>
                        <div className="col-sm-1">
                        
                            Place
                        </div>
                        <div className='col-sm-4'>
                            Name
                        </div>
                        <div className="col-sm-5">
                            Team
                        </div>
                        
                        <div className='col-sm-1'>
                            Points
                        </div> 
                        
                    </div>
                    <div className='mb-2'>
                    {this.state.results_list.map((item, index) => (
                        
                        <RacePageResult 
                          key={index} 
                          data_key={index}
                          event_name={item.event_name}
                          race_date={item.race_date}
                          race_city={item.race_city}
                          race_state={item.race_state}
                          cyc_org={item.cyc_org}
                          race_type={item.race_type}
                          discipline={item.discipline}
                          competitive={item.competitive}
                          gender={item.gender}
                          age={item.age}
                          class={item.class}
                          category={item.category}
                          distance={item.distance}
                          distance_type={item.distance_type}
                          place={item.place}
                          time={item.time}
                          laps={item.laps}
                          split={item.split}
                          points={item.points}
                          first={item.first}
                          last={item.last}
                          compid={item.compid}
                          bib={item.bib}
                          team={item.team}
                          comp_city={item.comp_city}
                          comp_state={item.comp_state}
                          comp_hometown={item.comp_hometown}
                        ></RacePageResult>  
                  ))
                  }
                  </div>
                </div>

                
                
                    
                
                
            </React.Fragment>
            
            ;
        } 
        
        
        else {
            content = 
            <div className='container-flex' onClick={() => this.setState({ expanded: true })}>
                <div className='row'>
                    <div className='col-sm-auto me-0 ms-0'>
                        <svg  xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z"/></svg>
                    </div>
                    <div className='col-sm '>
                        <h5>{new Date(this.state.race_date).toDateString()} {this.state.gender} {this.state.discipline} {this.state.category} {this.state.age} {this.state.class}</h5>
                    </div>
                </div>
            </div>
            ;
        }
    
        return (
        <div className="border border-2 rounded-3">
           
            {content}
        </div>
        );
    }
}
 
export default RaceEntry;